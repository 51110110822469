body {
  background-color: #0a192f;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.playerleaderboard-content {
  color: white;
  font-family: 'Raleway', sans-serif;
  text-align: center;
  margin: 50px auto; 
  max-width: 800px; 
}

.playerleaderboard-content h1 {
  font-size: 60px;
}

.playerleaderboard-content table {
  width: 100%;
  border: 15px solid #112240;
  border-radius: 15px;
  background-color: #112240;
}

.playerleaderboard-content tbody {
  background-color: #112240;
}

.playerleaderboard-content th,
.playerleaderboard-content td {
  padding: 8px;
  background-color: #112240;
  border: 1px solid #112240;
}

.playerleaderboard-content tbody tr:nth-child(odd) {
  background-color: #0a192f;
}

.playerleaderboard-content tbody tr:nth-child(even) {
  background-color: #112240;
}
