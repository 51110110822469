body {
    background-color: #0a192f;
    height: 100%;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
  }
  
.content {
    background-color: #112240;
    border: 25px solid #112240;
    border-radius: 25px;
    color: white;
    font-family: 'Raleway', sans-serif;
    margin: 50px;
    text-align: center;
}

.content h1 {
    font-size: 40px;
}

.role {
    display: inline-block;
}

.role ul {
    list-style: none;
}

.role li {
    display: inline-block;
    padding-right: 10px;
}
