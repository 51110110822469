.navbar {
    background-color: #112240;
    height: 77px;
}

.navbar-link {
    color: white;
    font-size: 20px;
    float: right;
    padding: 25px;
    text-decoration: none;
}

.navbar-link-home {
    color: white;
    float: left;
    font-size: 20px;
    font-weight: bold;
    padding: 25px;
    text-decoration: none;
}

.navbar-dropdown {
    float: right;
    position: relative;
}

.navbar-dropdown-btn {
    align-items: center;
    border: none;
    outline: none;
    color: white;
    padding: 25px;
    padding-top: 29px;
    background-color: #112240;
    margin: 0;
}

.navbar-dropdown-content a {
    float: none;
    color: white;
    padding: 15px 20px;
    text-decoration: none;
    display: block;
    text-align: left;
}

.navbar-dropdown-content {
    display: none;
    position: absolute;
    background-color: #112240;
    z-index: 1;
    top: calc(100%);
    right: 0;
}

.navbar-dropdown-content a:hover {
    background-color: #0a192f;
}

.navbar-dropdown:hover .navbar-dropdown-content {
    display: block;
}
