body {
    background-color: #0a192f;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
  }
  
.home-content {
  /* background-color: #112240;
  border: 25px solid #112240;
  border-radius: 25px; */
  color: white;
  font-family: 'Raleway', sans-serif;
  text-align: center;
  margin: 50px;
}

.home-content h1 {
  font-size: 60px;
  margin-bottom: 35px;
}

.home-content h2 {
  font-size: 40px;
  margin-bottom: 20px;
}

.home-content p {
  color: #8892b0;
  font-size: 30px;
  margin-bottom: 40px;
}

.home-link {
  background-color: blueviolet;
  border-radius: 10px;
  color: white;
  font-size: 30px;
  margin-right: 25px;
  padding: 15px;
  text-decoration: none;
}
  