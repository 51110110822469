body {
    background-color: #0a192f;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
  }
  
.forms-content {
  color: white;
  font-family: 'Raleway', sans-serif;
  text-align: center;
  margin: 50px;
}

.forms-content h1 {
  font-size: 60px;
}

  